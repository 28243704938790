import React from "react";
import {Router} from "react-router";
import {Routing} from "./routing/Routing";
import history from "./services/history";
import * as Sentry from "@sentry/react";


const App = () => {
    return (
        <>
            <Router history={history}>
                <React.Suspense fallback={""}>
                    <Routing/>
                </React.Suspense>
            </Router>
        </>
    );
}

export default Sentry.withProfiler(App);

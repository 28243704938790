import React from 'react';
import {Checkbox, Col, Form, Row} from "antd";
import PropTypes from 'prop-types'

const SurveyForm = ({colOffset, form}) => {

    const colStyle = {marginBottom: -30};

    return (
        <Row>
            <Col offset={colOffset} xs={24 - 2 * colOffset} style={{marginBottom: 10}} >
                <Form form={form} layout="horizontal">

                    {/*<Row>*/}
                    {/*    <Col xs={24} style={colStyle}>*/}
                    {/*        <Form.Item name='acceptanceLetter' valuePropName='checked' initialValue={false}>*/}
                    {/*          <Checkbox data-cy='acceptanceLetter'>Please send me a Acceptance Letter by email.</Checkbox>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {/*<Row>*/}
                    {/*    <Col xs={24} style={colStyle}>*/}
                    {/*        <Form.Item name='invitationLetter' valuePropName='checked' initialValue={false}>*/}
                    {/*            <Checkbox data-cy='invitationLetter'>Please send me a Invitation Letter for a Visa Application.</Checkbox>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row>
                        <Col xs={24} style={colStyle}>
                            <Form.Item name='certificate' valuePropName='checked' initialValue={false}>
                                <Checkbox data-cy='certificate'>I need certificate of attendance.</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Col>
        </Row>
    );
}

SurveyForm.propTypes = {
    user: PropTypes.object,
    action: PropTypes.func,
    colOffset: PropTypes.number,
};

SurveyForm.defaultProps = {
    colOffset: 2
}

// export default Form.create({})(SurveyForm);
export default SurveyForm;

import React from 'react'

export default {
    name: 'kpt2021',
    title: 'KomPlasTech 2021',
    subTitle: 'XXVII International Conference on Computer Methods in Materials Technology',
    conferenceSiteUrl: 'http://www.komplastech.agh.edu.pl/',
    registrationFormText: (<span>Complete the form below in order to register for the KomPlasTech 2021 Online event,
        March 8, 2021. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a
            href="mailto:komplast@agh.edu.pl">komplast@agh.edu.pl</a>.</span>),
    feeTabVisible: false,
    paperTabVisible: false,
    backgroundImage: '/background_ktp2021.webp',
    abstractTemplate: null,
    paperTemplate: null,
    copyrightsForm: null,
    sentryDsn: 'https://179bca37dc92475089165779b4313ff6@sentry.kisim.eu.org/3',
    feeText: null,
    menu: {
        papersList: 'List of full papers',
        submitPaper: 'Submit full paper'
    },
    fees: [
        {
            id: 'FULL_EARLY',
            label: 'Full early'
        },
        {
            id: 'FULL_LATE',
            label: 'Full late'
        },
        {
            id: 'STUDENT_EARLY',
            label: 'Student early'
        },
        {
            id: 'STUDENT_LATE',
            label: 'Student late'
        },
        {
            id: 'STAFF',
            label: 'Technical staff'
        },
        {
            id: 'INVITED',
            label: 'Invited'
        },
        {
            id: 'CASH',
            label: 'Cash on desk'
        }
    ],
    apiUrl: process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:9000/api',
}

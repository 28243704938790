import LoginForm from "../forms/Login";
import {BasicLayout} from "../views/BasicLayout";
import {Panel} from "../components/Panel";
import {GdprAlert} from "../components/Gdpr/GdprAlert";
import {Col, Row} from "antd";

function Login() {
    return (
        <BasicLayout>
            <Col xs={24}>
                <Row align='middle' justify='center'>
                    <Col sm={5} xs={24}>
                        <Panel>
                            <LoginForm/>
                        </Panel>
                    </Col>
                </Row>
            </Col>

            <Col xs={24}>
                <GdprAlert/>
            </Col>
        </BasicLayout>
    );
}

Login.propTypes = {};
Login.defaultProps = {};

export default Login;

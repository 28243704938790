import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Input, Row, Select, Radio} from 'antd';
import config from "../../config";
import Author from "../common/Author";
import _ from "lodash-es";
import If from "babel-plugin-jsx-control-statements";
import styles from './AbstractSubmission.module.scss'
import capitalValidator from "../../validators/notAllowAllCapital";
import {PlusOutlined, RightOutlined} from "@ant-design/icons";
import authorSizes from './Author.sizes'
import {moveDownAuthor, moveUpAuthor} from "../common/Author";
import {KeywordsSelect} from "../../components/KeywordsSelect";
const FormItem = Form.Item;
const { TextArea } = Input;

const AbstractSubmission = ({
                                initialValues,
                                sessions,
                                keywords,
                                onKeywordsSearch,
                                type,
                                form,
                                onSubmit
                            }) => {

    const [presenter, setPresenter] = useState(0);

    // Set form values
    useEffect(() => {
        if(initialValues.authors && initialValues.authors.length > 0) {
            const formValues = {
                ...initialValues,
            }

            form.setFieldsValue(formValues)
            setPresenter(initialValues.presenter);  // TODO: FixIt
        }
    }, [initialValues, form]);



    const InfoMessage = () => {
        return (
            <div>
                <ul>
                    <li>The abstract, not exceeding 2000 characters incl spaces and punctuation, must be written in English.
                    </li>
                    <li>Acceptance or rejection letters and all future correspondence will be emailed to the <strong>Primary
                        Author</strong>.
                    </li>
                </ul>
            </div>
        )
    };


    const onFinish = (values) => {
        onSubmit({...values, presenter})
    }

    const getTitleCaption = () => {
        switch(type) {
            case 'submit': return "Submit new abstract";
            case 'edit':
            case 'resubmit': return `Edit: '${_.truncate(initialValues.title, {length: 150, omission: '...'}) || ''}'`;
            default: return '';
        }
    };

    const getSubmitButtonCaption = () => {
        switch(type) {
            case 'submit': return 'Submit';
            case 'edit': return 'Save';
            case 'resubmit': return 'Submit';
            default: return 'Save';
        }
    };

    return (
        <Card title={getTitleCaption()} style={{marginBottom: 20}}>
            {type === 'submit' && <Row>
                <Col xs={24}>
                    <Alert
                      style={{marginBottom: 20}}
                      showIcon
                      closable
                      message="Abstract submission rules & guidelines"
                      description={<InfoMessage/>}
                      type="info"
                    />

                </Col>
            </Row>
            }


            <Form form={form} onFinish={onFinish} layout='vertical'>
                <p className="ant-form-item-required">Authors:</p>
                <Form.List name="authors">
                    {(fields, {add, remove}) => {
                        return (
                            <div>
                                <Radio.Group
                                    className={styles.authorsGroup}
                                    name="presenter"
                                    value={presenter}
                                    defaultValue={presenter}
                                    onChange={(e) => {setPresenter(e.target.value)}}
                                >
                                {
                                    fields.map((field, index) => (

                                        <Author
                                            sizes={authorSizes}
                                            showSpeakerSelection={true}
                                            field={field}
                                            key={field.key}
                                            index={index}
                                            count={fields.length}
                                            onRemove={remove}
                                            onMoveDown={() => moveDownAuthor(form, index)}
                                            onMoveUp={() => moveUpAuthor(form, index)}
                                        />))

                                }
                                </Radio.Group>
                                <Row gutter={25}>
                                    <Col xs={3}>
                                        <FormItem>
                                            <Button type="primary" onClick={() => add()}>
                                                <PlusOutlined/> Add author
                                            </Button>
                                        </FormItem>
                                    </Col>
                                </Row>

                            </div>
                        )
                    }}


                </Form.List>


                <Row>
                    <Col xs={24}>
                        <FormItem
                            name='title'
                            label="Title"

                            rules={[
                                {
                                    required: true,
                                    message: 'Title is required'
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        return capitalValidator(rule, value)
                                    }
                                })

                                ]}>
                                <Input data-cy='title' placeholder="Title of the presentation"/>
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={25}>
                    <Col xs={24} lg={10}>
                        <FormItem
                            label="Session"
                            name="session"
                            rules={[
                                {required: true, message: 'Please select type of submission!'},
                            ]}
                        >
                                <Select data-cy='session'>
                                    {sessions.map((value) => <Select.Option value={value.acronym}
                                                                                       key={value.acronym}>{value.title}</Select.Option>)}
                                </Select>
                        </FormItem>
                    </Col>

                    <Col lg={14} xs={24}>
                        <FormItem
                            label="Keywords"
                            name="keywords"
                            rules={[{
                                    required: true, message: 'Keywords are required',
                            }]}
                        >
                            <KeywordsSelect data-cy='keywords' onSearch={onKeywordsSearch} keywords={keywords}/>
                        </FormItem>
                    </Col>
                </Row>

                <Row type='flex' justify='center'>
                    <Col xs={24}>
                        <FormItem
                            label="Abstract"
                            name="content"
                            rules={[{
                                required: true, message: 'Abstract is required',
                            }]}
                            >

                            <TextArea
                                autoSize={{ minRows: 4, maxRows: 16 }}
                                showCount
                                maxLength={2000}
                                placeholder="Text of the abstract"
                            />
                        </FormItem>
                    </Col>
                </Row>

                <If condition={type === 'resubmit'}>
                    <Row>
                        <Col xs={24}>
                            <FormItem
                                label="Comments"
                                name="comment"
                            >
                                    <Input.TextArea placeholder="Message for the reviewer" rows={3}/>
                            </FormItem>
                        </Col>
                    </Row>
                </If>
                <FormItem >
                    <Button type="primary" htmlType="submit" icon={<RightOutlined/>}>{getSubmitButtonCaption()}</Button>
                </FormItem>
            </Form>
        </Card>
    );
};

export default AbstractSubmission;

import ForgotPasswordForm from "../forms/RequestNewPassword";
import {BasicLayout} from "../views/BasicLayout";
import {Col, Row} from "antd";
import {Panel} from "../components/Panel";

const ForgotPassword = () => {
    return (
    <BasicLayout>
            <Col xs={24}>
                <Row align='middle' justify='center'>
                    <Col sm={5} xs={24}>
                        <Panel>
                            <ForgotPasswordForm />
                        </Panel>
                    </Col>
                </Row>
            </Col>
    </BasicLayout>
    );
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;

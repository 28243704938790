import React from 'react';
import {Col, Form, Input, Row, Select} from 'antd'
import If from "babel-plugin-jsx-control-statements"
import PropTypes from 'prop-types'
import config from "../../config"
import capitalValidator from "../../validators/notAllowAllCapital"
import {useDispatch} from "react-redux";
import emailAvailable from "../../validators/emailAvailable";
import {compareToFirstPassword} from "../../validators/comparePasswords";

const FormItem = Form.Item;

const {registrationFormText} = config;

const CredentialsForm = ({form, colOffset, mode}) => {
    const dispatch = useDispatch();

    return (
        <Form form={form} layout="vertical" scrollToFirstError>
            <Row>
                <Col offset={colOffset} xs={24 - 2 * colOffset}>
                    <If condition={mode === 'register'}>
                        <Row>
                            <Col xs={24}>
                                <p style={{textAlign: 'justify', marginBottom: 30, marginTop: 20}}>
                                    {registrationFormText}
                                </p>
                            </Col>
                        </Row>
                    </If>

                    <Row>
                        <Col xs={8}>
                            <FormItem
                                label="E-mail"
                                name='email'
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }, {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return emailAvailable(rule, value, dispatch)
                                        }
                                    })
                                    ]}>
                                <Input data-cy="email" autoComplete="off"/>
                            </FormItem>
                        </Col>
                        <Col/>

                        <Col xs={7} offset={1}>
                            <FormItem
                                label="Password"
                                name='password'
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: mode === 'register',
                                        message: 'Please input your password!',
                                    },
                                    {
                                        min: 6, message: 'Password should have at least 6 characters'
                                    }]}
                            >

                                <Input.Password data-cy="password1"/>

                            </FormItem>
                        </Col>

                        <Col xs={7} offset={1}>
                            <FormItem
                                label="Confirm Password"
                                name='confirm'
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: mode === 'register',
                                        message: 'Please confirm your password!',
                                    },
                                    compareToFirstPassword
                                ]}>
                                <Input.Password data-cy="password2" />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <FormItem
                                label="Title"
                                name='title'
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your title'
                                    }
                                    ]}
                            >
                                    <Select data-cy="title">
                                        <Select.Option value="Eng">Eng.</Select.Option>
                                        <Select.Option value="MSc">MSc</Select.Option>
                                        <Select.Option value="PhD">PhD</Select.Option>
                                        <Select.Option value="Prof">Prof.</Select.Option>
                                    </Select>
                            </FormItem>
                        </Col>

                        <Col xs={9} offset={1}>
                            <FormItem
                                name='firstName'
                                label={(
                                    <span>First name</span>
                                )}
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your first name!',
                                        whitespace: true
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return capitalValidator(rule, value)
                                        }
                                    })
                                    ]}
                            >
                                    <Input data-cy="firstName"/>
                             </FormItem>
                        </Col>
                        <Col xs={9} offset={1}>
                            <FormItem
                                name='lastName'
                                label='Last name'
                                validateTrigger="onBlur"
                                rules={[{
                                    required: true,
                                    message: 'Please input your last name!',
                                    whitespace: true
                                },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            return capitalValidator(rule, value)
                                        }
                                    })
                                ]}
                                >
                                <Input data-cy="lastName"/>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                name='affiliation'
                                label='Affiliation'
                                validateTrigger="onBlur"
                                extra="The name of your institute, university or company."
                                rules={[{
                                    required: true,
                                    message: 'Please input your affiliation!',
                                    whitespace: true
                                }]}
                            >
                                <Input data-cy="affiliation"/>
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </Form>
    );
};

CredentialsForm.defaultProps = {
    colOffset: 2
};

CredentialsForm.propTypes = {
    formContent: PropTypes.object,
    user: PropTypes.object,
    colOffset: PropTypes.number
};

// export default Form.create({})(CredentialsForm);
export default CredentialsForm;

import {BasicLayout} from "../views/BasicLayout";
import {Col, Row} from "antd";
import {FrownOutlined} from '@ant-design/icons';
import {Panel} from "../components/Panel";
import styled from 'styled-components'

const FrownOutlinedStyled = styled(FrownOutlined)`
  font-size: 72px;
  padding: 5px 20px 0 0;
`

const ErrorTitle = styled.p`
  font-size: 2em;
  padding: 0;
  margin: 0;
  font-weight: bold;
`

const ErrorText = styled.p`
  font-size: 1.6em;
  padding: 0;
  margin: 0;
`

const NotFound = () => {
    return (
        <BasicLayout>
            <Col xs={24}>
                <Row align='middle' justify='center'>
                    <Col sm={9} xs={24}>
                        <Panel>
                            <Row xs={24} wrap={false}>
                                <Col><FrownOutlinedStyled/></Col>
                                <Col flex={"auto"}>
                                    <ErrorTitle>
                                        We are sorry!
                                    </ErrorTitle>
                                    <ErrorText>
                                        We can't seem to find the page your're looking for.
                                    </ErrorText>
                                </Col>
                            </Row>
                        </Panel>
                    </Col>
                </Row>
            </Col>
        </BasicLayout>
    );
}


export default NotFound;

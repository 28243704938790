import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import config from "../config";
import history from "./history"

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: config.sentryDsn,
        release: 'suberus-gui@' + global.appVersion,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),

        ],
        tracesSampleRate: 1.0,
        });
} else {
    console.log('Sentry init skipped');
}
